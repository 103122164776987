export var KeyCode;
(function (KeyCode) {
    KeyCode[KeyCode["Tab"] = 9] = "Tab";
    KeyCode[KeyCode["Enter"] = 13] = "Enter";
})(KeyCode || (KeyCode = {}));
export function isJsonResponse(instance) {
    return instance && (!!instance.payload ||
        instance.errors instanceof Array ||
        typeof instance.message === 'string' ||
        typeof instance.redirectUrl === 'string');
}
export class ViewModelBase {
    constructor(source) {
        this.editAction = source.EditAction;
        this.canEdit = source.CanEdit;
        this.copyAction = source.CopyAction;
        this.canCopy = source.CanCopy;
        this.deleteAction = source.DeleteAction;
        this.canDelete = source.CanDelete;
        this.purgeAction = source.PurgeAction;
        this.canPurge = source.CanPurge;
    }
}
