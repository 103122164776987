import { applyBindings, observable } from 'knockout';
import moment from 'moment';
import { ButtonGroup } from 'Shared/Scripts/DeferredButton';
import { ajaxSubmit, createElementFromTemplate, logout, submitFormAjax } from 'Shared/Scripts/Helpers';
import { Intercom, SessionEvent } from 'Shared/Scripts/Intercom';
import { createModal } from 'Shared/Scripts/ModalHelper';
import { addPersistentNotification, showError } from 'Shared/Scripts/Notification';
import 'Shared/Scripts/UnobtrusiveValidation';
import { HttpStatusCode } from 'Shared/Types/HttpStatusCode';
export class TimeoutNotifier {
    constructor(allowReauthentication) {
        this.sessionEnd = moment.invalid();
        this.remainingSecondsDisplay = observable(0);
        this.secondsToTrigger = 120;
        this.processingLogout = false;
        this.allowReauthentication = allowReauthentication;
        this.intercom = Intercom.create();
    }
    get enabled() {
        return !!$('#TimeoutNotifierTemplate').length;
    }
    get reauthenticationEnabled() {
        return !!(this.enabled && this.allowReauthentication && $('#ReauthenticationTemplate').length);
    }
    async init() {
        if (this.enabled) {
            this.countdownModal = this.createCountdownModal();
            this.authenticationModal = this.createAuthenticationModal();
            this.subscribeToEvents();
            await this.getSessionExpiration();
            window.setInterval(async () => {
                if (this.sessionEnd.isValid()) {
                    await this.getSessionExpiration();
                }
            }, 60000);
            if (this.countdownModal) {
                applyBindings(this, this.countdownModal[0]);
                this.clear(true);
            }
            if (this.authenticationModal) {
                applyBindings(this, this.authenticationModal[0]);
            }
        }
        return this;
    }
    subscribeToEvents() {
        this.intercom.on({
            category: [SessionEvent.LoggedOut],
            handler: () => this.logout(false)
        });
        this.intercom.on({
            category: [SessionEvent.TimedOut, SessionEvent.ExpirationInvalidated, SessionEvent.ReauthenticationRequested],
            handler: () => {
                if (this.reauthenticationEnabled) {
                    this.showAuthentication(true);
                }
                else {
                    this.logout(true);
                }
            }
        });
        this.intercom.on({
            category: [SessionEvent.ExpirationUpdated, SessionEvent.Reauthenticated, SessionEvent.Renewed],
            handler: (event) => {
                const newExpiration = event.data ? moment.utc(event.data) : moment.invalid();
                this.sessionEnd = newExpiration;
                const remainingTime = this.sessionEnd.diff(moment.utc(), 'seconds');
                if (remainingTime <= 0) {
                    this.intercom.send({
                        category: SessionEvent.TimedOut
                    }, true);
                }
                if (event.category !== SessionEvent.ExpirationUpdated || remainingTime > this.secondsToTrigger) {
                    this.clear(true);
                }
            }
        });
    }
    createTriggerTimeout() {
        if (this.sessionEnd.isValid()) {
            let triggerTime = this.sessionEnd.diff(moment.utc(), 'seconds') - this.secondsToTrigger;
            triggerTime = triggerTime > 0 ? triggerTime : 1;
            const id = window.setTimeout(() => {
                this.showCountdown(true);
            }, triggerTime * 1000);
            this.triggerTimeoutId = id;
        }
    }
    createCountdownInterval() {
        if (this.sessionEnd.isValid()) {
            const id = window.setInterval(() => {
                this.countdown();
            }, 1000);
            this.countdownIntervalId = id;
        }
    }
    countdown() {
        if (this.sessionEnd.isValid() && this.countdownModal && this.isCountdownModalVisible()) {
            const remainingTime = this.sessionEnd.diff(moment(), 'seconds');
            if (remainingTime <= 0) {
                this.intercom.send({
                    category: this.allowReauthentication ? SessionEvent.ReauthenticationRequested : SessionEvent.TimedOut
                }, true);
            }
            else if (remainingTime > this.secondsToTrigger) {
                this.showCountdown(false);
            }
            else {
                this.remainingSecondsDisplay(remainingTime);
            }
        }
    }
    isCountdownModalVisible() {
        if (this.countdownModal) {
            return (this.countdownModal.hasClass('animating') || this.countdownModal.hasClass('active'));
        }
        return false;
    }
    createCountdownModal() {
        if (this.enabled) {
            const template = $(createElementFromTemplate('TimeoutNotifierTemplate'));
            const modal = createModal($(template), {
                allowMultiple: true,
                autofocus: false,
                closable: false,
                onApprove: () => {
                    this.renewSession();
                    return true;
                },
                onDeny: () => {
                    this.intercom.send({
                        category: SessionEvent.LoggedOut
                    }, true);
                    return false;
                },
                onHidden: () => {
                    return;
                }
            }, false);
            return modal;
        }
        return undefined;
    }
    isAuthenticationModalVisible() {
        if (this.authenticationModal) {
            return (this.authenticationModal.hasClass('animating') || this.authenticationModal.hasClass('active'));
        }
        return false;
    }
    createAuthenticationModal() {
        if (this.reauthenticationEnabled) {
            const template = $(createElementFromTemplate('ReauthenticationTemplate'));
            const modal = createModal($(template), {
                allowMultiple: true,
                closable: false,
                onApprove: () => {
                    return false;
                },
                onDeny: () => {
                    this.intercom.send({
                        category: SessionEvent.LoggedOut
                    }, true);
                    return false;
                },
                onHidden: () => {
                    return;
                }
            }, false);
            return modal;
        }
        return undefined;
    }
    clear(reset) {
        if (this.enabled && reset) {
            this.showCountdown(false);
        }
        if (this.reauthenticationEnabled && this.authenticationModal && reset) {
            this.showAuthentication(false);
            this.authenticationModal.trigger('reset');
        }
        if (this.triggerTimeoutId) {
            window.clearTimeout(this.triggerTimeoutId);
        }
        if (this.countdownIntervalId) {
            window.clearInterval(this.countdownIntervalId);
        }
        if (reset) {
            this.createTriggerTimeout();
            this.createCountdownInterval();
        }
    }
    logout(timeout = false) {
        var _a;
        // Keeps IE from submitting way too many logout requests, due to incorrect LocalStorage implementation.
        if (!this.processingLogout) {
            if (this.countdownModal) {
                new ButtonGroup(this.countdownModal[0], { submit: false, logout: true });
            }
            if (timeout) {
                const isSsoEnvironment = ((_a = window.location.hostname) === null || _a === void 0 ? void 0 : _a.indexOf('il')) >= 0;
                if (!isSsoEnvironment) {
                    addPersistentNotification('info', 'Session Expired', 'You have been logged out due to inactivity.', false);
                }
            }
            this.clear(false);
            logout();
            this.processingLogout = true;
        }
    }
    showCountdown(show) {
        if (this.countdownModal) {
            this.countdownModal.modal(show ? 'show' : 'hide');
        }
    }
    showAuthentication(show) {
        if (this.authenticationModal) {
            this.showCountdown(false);
            this.authenticationModal.modal(show ? 'show' : 'hide');
        }
    }
    async getSessionExpiration() {
        var _a;
        try {
            //--Check for an updated expiration if...
            //	There's no valid expiration or
            //	We're within the timeframe where the session timeout modal should show up
            if (!this.sessionEnd.isValid() || this.sessionEnd.diff(moment.utc(), 'seconds') < this.secondsToTrigger) {
                const result = await $.get('/Account/SessionExpiration');
                const expiration = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : '';
                if (expiration && typeof expiration === 'string' && expiration.trim()) {
                    this.intercom.send({
                        data: expiration,
                        category: SessionEvent.ExpirationUpdated
                    }, true);
                }
                else {
                    throw new Error(`Invalid expiration: ${expiration}`);
                }
            }
        }
        catch (err) {
            if (this.sessionEnd.isValid()) {
                this.intercom.send({
                    category: SessionEvent.ExpirationInvalidated
                }, true);
            }
        }
    }
    submitAuthentication() {
        if (this.authenticationModal && this.authenticationModal.valid()) {
            this.reauthenticateSession();
        }
    }
    async reauthenticateSession() {
        var _a;
        if (this.authenticationModal) {
            // Unfortunately have to have the button logic here due to Semantic Modal callback limitations.
            const buttons = new ButtonGroup(this.authenticationModal[0], { submit: true, logout: false });
            try {
                await $.get('/Account/RefreshAntiForgeryToken');
                const result = await submitFormAjax(this.authenticationModal[0]);
                const expiration = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : '';
                if (expiration && typeof expiration === 'string' && expiration.trim()) {
                    this.intercom.send({
                        category: SessionEvent.Reauthenticated,
                        data: expiration
                    }, true);
                }
            }
            catch (err) {
                let errMessage = 'An error occurred logging in, please try again.';
                if (err.status === HttpStatusCode.UnprocessableContent) {
                    const errResponse = err.responseJSON;
                    errMessage = errResponse.message || errMessage;
                }
                showError('Error', errMessage, undefined, true);
            }
            finally {
                buttons.resolve();
            }
        }
    }
    async renewSession() {
        var _a;
        try {
            const result = await ajaxSubmit({
                url: '/Account/RenewSession',
                method: 'POST'
            });
            const expiration = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : '';
            if (expiration && typeof expiration === 'string' && expiration.trim()) {
                this.intercom.send({
                    category: SessionEvent.Renewed,
                    data: expiration
                }, true);
            }
            else {
                throw new Error(`Invalid expiration: ${expiration}`);
            }
        }
        catch (err) {
            if (this.sessionEnd.isValid()) {
                this.intercom.send({
                    category: SessionEvent.ExpirationInvalidated
                }, true);
            }
        }
    }
    static async createNotifier(allowReauthentication = false) {
        return await new TimeoutNotifier(allowReauthentication).init();
    }
}
